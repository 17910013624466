import React, { useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./PublicationArticleItem.css";

export default function PublicationSlider(props) {
  let { article } = props;

  return (
    <Link className="publicationArticleItem" to={`/research/${article.slug}`}>
      <GatsbyImage
        className="publicationArticleImage _1across"
        placeholder="blurred"
        image={getImage(article.heroImage)}
      />
      <div className="publicationArticleItemText">
        <h2 className="publicationArticleItemTitle">{article.title}</h2>
        <p className="publicationArticleItemAuthor">
          {article.author ? article.author.name : ""}
        </p>
      </div>
    </Link>
  );
}
