import * as React from "react";
import Layout from "../components/Layout";
import PublicationSlider from "../components/PublicationSlider";
import PublicationArticleItem from "../components/PublicationArticleItem";
import { Helmet } from "react-helmet";

import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p>{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    // [BLOCKS.EMBEDDED_ASSET]: (node) => {
    //   return (
    //     <>
    //       <h2>Embedded Asset</h2>
    //       <pre>
    //         <code>{JSON.stringify(node, null, 2)}</code>
    //       </pre>
    //     </>
    //   );
    // },
    "embedded-asset-block": (node) => {
      const { gatsbyImageData } = node.data.target;
      const image = getImage(gatsbyImageData);

      if (!gatsbyImageData) {
        // asset is not an image
        return null;
      }
      return <GatsbyImage placeholder="blurred" image={image} />;
    },
  },
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

// markup
const PublicationPage = ({ data }) => {
  const { metadata, publicationTitle, publicationImages, articles } =
    data.contentfulPublicationPage;

  const articlesExpanded = articles.map((article) => (
    <PublicationArticleItem article={article} />
  ));

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Publication • System of Systems</title>
      </Helmet>
      {publicationImages && <PublicationSlider images={publicationImages} />}

      <div className="_2across gridCentered">
        {publicationTitle && (
          <h1 className="publicationTitle">{publicationTitle}</h1>
        )}
        <div className="publicationMetadata">
          {metadata && renderRichText(metadata, options)}
        </div>
        {articlesExpanded}
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulPublicationPage {
      publicationTitle
      publicationImages {
        gatsbyImageData(width: 1600)
        title
      }
      metadata {
        raw
      }
      articles {
        heroImage {
          gatsbyImageData(width: 600)
          title
        }
        title
        author {
          name
        }
        slug
      }
    }
  }
`;

export default PublicationPage;
