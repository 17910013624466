import React, { useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function PublicationSlider(props) {
  const [publicationImage, setPublicationImage] = useState(0);

  const { images } = props;

  const nextImage = () => {
    if (publicationImage >= images.length - 1) {
      setPublicationImage(0);
    } else {
      setPublicationImage(publicationImage + 1);
    }
  };

  const prevImage = () => {
    if (publicationImage >= images.length - 1) {
      setPublicationImage(0);
    } else {
      setPublicationImage(publicationImage + 1);
    }
  };

  const publicationImagesExpanded = images.map((image, index) => (
    <GatsbyImage
      key={image.title}
      image={getImage(image.gatsbyImageData)}
      alt={image.title}
      loading="eager"
      className={publicationImage === index ? "thisone" : "notthisone"}
    />
  ));

  return (
    <div
      className="_4across publicationSlider topOfPage"
      onClick={() => {
        nextImage();
      }}
    >
      <div className="sliderWrapper">
        {/* <GatsbyImage
          key={images[publicationImage].title}
          image={getImage(images[publicationImage].gatsbyImageData)}
          alt={images[publicationImage].title}
        /> */}

        {/* {publicationImagesExpanded[publicationImage]} */}
        {publicationImagesExpanded}
      </div>
    </div>
  );
}
